import React from 'react';
import * as styles from './Pipeline.module.scss';
import clsx from 'clsx';
import parse from 'html-react-parser';
import Title from '../Title/Title';

export default function Pipeline({ data={} }) {
    return (
        <section className={clsx(styles.pipeline)}>
            <div className={clsx('section-wrapper')}>
                <div className={styles.content}>
                    <div className={styles.headline}>
                      <Title>
                        {parse(data.headline.html)}
                      </Title>
                    </div>
                    <picture className={styles.image}>
                        <source media="(min-width:992px)" srcSet={data.image.url}/>
                        <img src={data.mobileImage.url} alt={data.image.alt} />
                    </picture>
                </div>
            </div>
        </section>
        )
}