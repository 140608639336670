import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Hero from '../components/Hero/Hero';
import InfoBox from '../components/InfoBox/InfoBox';
import Callout from '../components/Callout/Callout';
import ImageText from '../components/ImageText/ImageText';
import Pipeline from '../components/Pipeline/Pipeline';

export default function PipelinePage({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-pipeline')} text={data.page.data.header_text.html} image={data.page.data.header_image} />
        <Breadcrumb className={data.page.data.breadcrumb_css_class && data.page.data.breadcrumb_css_class.text} list={data.page.data.breadcrumb}/>
        <Pipeline data={{ headline: data.page.data.pipeline_headline, image: data.page.data.pipeline_image, mobileImage: data.page.data.pipeline_image_mobile}}/>
        {data.page.data.sections.map((section, index) => (
          <ImageText key={index} data={section} />
        ))}
        <InfoBox data={{ headline: data.page.data.stats1_headline, stats: data.page.data.stats1, css_class: data.page.data.stats1_css_class }} />
        <InfoBox data={{ headline: data.page.data.stats2_headline, stats: data.page.data.stats2, css_class: data.page.data.stats2_css_class }} />
        <Callout
          text={ data.page.data.callout_text.html }
          cta={{
            text: data.page.data.callout_cta_text.text,
            url: data.page.data.callout_cta_url
          }}
          image={ data.page.data.callout_image }
        />
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const PipelineQuery = graphql`
query {
  page: prismicPipeline {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      } 
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
			sections {
        headline {
          html
        }
        paragraph {
          html
        }
        image {
          url
          alt
        }
        css_class {
          text
        }
        image_css_class {
          text
        }
        background_image {
          url
        }
        component_type {
          text
        }
        height_max {
          text
        }
      }  
      stats1_headline {
        html
      }
      stats1_css_class {
        text
      }
      stats1 {
        headline {
          text
        }
        paragraph {
        	text
        }
        image {
    			url
          alt
        }
        css_class {
          text
        }
      }
      stats2_headline {
        html
      }
      stats2_css_class {
        text
      }
      stats2 {
        headline {
          text
        }
        paragraph {
        	text
        }
        image {
    			url
          alt
        }
        css_class {
          text
        }        
      }
      callout_text {
        html
      }
      callout_image {
        url
        alt
      }
      callout_cta_text {
        text
      }
      callout_cta_url {
        target
        slug
        link_type
        url
      }
      pipeline_image {
        url
        alt
      }
      pipeline_headline {
        html
      }
      pipeline_image_mobile {
        alt
        url
      }
    }
  }
}
`
